import React, { useEffect, useState } from "react"
import axios from "axios"
import FileInput from "../components/atoms/FileInput"
import Button from "../components/atoms/Button"
import Layout from "../components/templates/Layout"

const UploadImage = props => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")

  const selectFile = e => {
    setSelectedFile(e.target.files[0])
  }

  const imageUpload = () => {
    const formData = new FormData()
    formData.append("file", selectedFile)
    let file_name = selectedFile.name
    if (file_name.includes(" ")) {
      file_name.replace(/ /g, "_")
    }
    axios
      .get("http://0.0.0.0:8000/blog/tech/image", {
        params: { file_name },
      })
      .then(res => {
        console.log(res)
        axios
          .put(res.data.presigned_url, selectedFile, {
            headers: {
              "Content-Type": res.data.content_type,
            },
          })
          .then(result => {
            setImageUrl(res.data.image_url)
          })
          .catch(error => {
            alert("ERROR " + JSON.stringify(error))
          })
        alert("성공")
      })
      .catch(err => {
        alert("실패")
      })
  }

  return (
    <Layout>
      <h1>hello</h1>
      <FileInput onChange={selectFile} />
      <Button onClick={imageUpload} />
      {imageUrl === "" ? (
        <p>이미지가 업로드 되면 s3 url이 나타납니다!</p>
      ) : (
        <p>{imageUrl}</p>
      )}
    </Layout>
  )
}

export default UploadImage
