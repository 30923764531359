import React from "react"

const FileInput = ({ onChange }) => {
  return (
    <input
      type="file"
      accept=".gif, .jpg, .png, .jpeg"
      name="profile_img"
      onChange={onChange}
    />
  )
}

export default FileInput
